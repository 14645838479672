<template>
  <div class="mb-3">
    <titulo-visualizar-nota-fiscal />
    <b-tabs v-model="tabIndex" fill content-class="mt-3">
      <!-- aba 01: dados gerais -->
      <b-tab :title="$t('SUPRIMENTOS_LANCAMENTOS_NF.DADOS_GERAIS')">
        <dados :dados="notaFiscalDados" />
      </b-tab>

      <!-- aba 02: impostos -->
      <b-tab :title="$t('SUPRIMENTOS_LANCAMENTOS_NF.IMPOSTOS')">
        <impostos :dados="notaFiscalDados" />
      </b-tab>

      <!-- aba 03: pagamento -->
      <b-tab :title="$t('SUPRIMENTOS_LANCAMENTOS_NF.PAGAMENTO')">
        <pagamento
          :dados="notaFiscalDados"
          :valorTotalImpostos="propsAuxiliares.valorTotalImpostos"
          @alterar-tab="alterarTab"
        />
      </b-tab>
          
      <!-- aba 04: itens do pedido -->
      <b-tab :title="$t('SUPRIMENTOS_LANCAMENTOS_NF.ITENS_PEDIDO')">
        <itens :dados="notaFiscalDados" />
      </b-tab>

      <!-- aba 05: observações -->
      <b-tab :title="$t('SUPRIMENTOS_LANCAMENTOS_NF.OBSERVACOES')">
        <observacoes :dados="notaFiscalDados" />
      </b-tab>
    </b-tabs>

    <tabs-btns :tabIndex="tabIndex" :ultimaTab="4" @alterar-tab="alterarTab" />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';

// Services:
import NotaFiscalService from '@/common/services/nota-fiscal/nota-fiscal.service.js';

// Components:
import TabsBtns from '@/components/btns/TabsBtns';
import {
  Dados,
  Impostos,
  Itens,
  Pagamento,
  Observacoes,
  TituloVisualizarNotaFiscal
} from '@/views/suprimentos/lancamentos-nf/components/nota-fiscal/visualizacao/index.js';


export default {
  components: {
    TituloVisualizarNotaFiscal,
    Dados,
    Impostos,
    Itens,
    Pagamento,
    Observacoes,
    TabsBtns,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('SUPRIMENTOS.SUPRIMENTOS') },
      { titulo: this.$t('SUPRIMENTOS.LANCAMENTOS_NF') },
      { titulo: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.LANCAR_NF') },
    ]);
    this.getNotaFiscal();
  },
  data() {
    return {
      notaFiscalDados: {},
      tabIndex: 0,
      refs: [],
      propsAuxiliares: {
        valorTotalImpostos: 'Adicione impostos para visualizar soma',
      },
    };
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getNotaFiscal() {
      this.$store.dispatch(START_LOADING);
      NotaFiscalService.obterPorId(this.$route.params.id)
        .then(({ data }) => {
          this.notaFiscalDados = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    alterarTab(tab) {
      this.tabIndex = tab;
    },
    calcularValorTotalImpostos() {
      let valorTotalImpostos = 0;
      this.notaFiscalDados.impostos.forEach((element) => {
        valorTotalImpostos += parseFloat(element.valor);
      });
      this.propsAuxiliares.valorTotalImpostos = valorTotalImpostos.toString();
    },
  },
};
</script>
