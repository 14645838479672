<template>
  <div>
    <cabecalho title="SUPRIMENTOS_LANCAMENTOS_NF.OBSERVACOES" />
    <b-card>
      {{ observacoes }}
    </b-card>
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
  },
  computed: {
    observacoes() {
      return this.dados.observacoes && this.dados.observacoes.length != 0
        ? this.dados.observacoes
        : this.$t('SUPRIMENTOS_LANCAMENTOS_NF.SEM_OBSERVACOES_CADASTRADAS');
    },
  },
};
</script>
