// FORMATAÇÃO CNPJ:
const cnpjComPontuacao = (cnpj) => {
  let cnpjFormatado;
  try {
    cnpjFormatado = cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  } catch {
    return cnpj;
  }
  return cnpjFormatado;
};
const cnpjSemPontuacao = (value) => {
  return value.replace(/\D/g, '');
};

export default {
  cnpjComPontuacao,
  cnpjSemPontuacao,
};
