<template>
  <b-table
    :fields="table.fields"
    :items="table.items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  />
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  watch: {
    dados() {
      if (this.dados) {
        this.table.items.push(this.dados);
      }
    },
  },
  data() {
    return {
      table: {
        items: [],
        fields: [
          {
            key: 'tipoNotaFiscal',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.TIPO_NF'),
            sortable: true,
          },
          {
            key: 'formaPagamento.descricao',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.FORMA_PAGAMENTO'),
            sortable: true,
          },
          {
            key: 'frete',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_FRETE'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'descontos',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_DESCONTOS'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'impostos',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_IMPOSTOS'),
            formatter: (value) => {
              return value ? this.calcularImpostos(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'valorTotal',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_TOTAL'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
        ],
      },
    };
  },
  methods: {
    calcularImpostos(impostos) {
      let valorTotalImpostos = 0;
      impostos.forEach((element) => {
        valorTotalImpostos += parseFloat(element.valor);
      });
      valorTotalImpostos.toString();
      valorTotalImpostos = helpers.formatarReal(valorTotalImpostos);

      return valorTotalImpostos;
    },
  },
};
</script>
