import ApiService from '@/common/api/api.service'

let apiBasePath = 'NotaFiscal'

const NotaFiscalService = {
    // FUNÇÕES CRUD:
    async criar (form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async obterPorId (notaFiscalId) {
        let { data } = await ApiService.get(`${apiBasePath}`, {
            params: {
                id: notaFiscalId
            }
        })
        return data
    },
    async excluir (notaFiscalId) {
        let { data } = await ApiService.delete(`${apiBasePath}?id=${notaFiscalId}`)
        return data
    },
    // FUNÇÕES PARCELAS:
    async gerarParcelas (form) {
        let { data } = await ApiService.get(`${apiBasePath}/parcelas`,
            {
                params: {
                    DataEmissao: form.dataEmissao,
                    FormaPagamentoId: form.formaPagamentoId,
                    HistoricoLancamentoId: form.historicoLancamentoId,
                    ValorDocumento: form.valorTotal
                }
            }
        )
        return data
    },
    // FUNÇÕES FILTRO:
    async obterPorFiltro (filtro, paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
            {
                params: {
                    DataEmissaoInicio: filtro.dataEmissaoInicio,
                    DataEmissaoAte: filtro.dataEmissaoAte,
                    DataVencimentoInicio: filtro.dataVencimentoInicio,
                    DataVencimentoAte: filtro.dataVencimentoAte,
                    NumeroNotaFiscal: filtro.numeroNotaFiscal,
                    TamanhoDaPagina: paginacao.tamanhoDaPagina,
                    NumeroDaPagina: paginacao.numeroDaPagina,
                }
            }
        )
        return data
    }
}

export default NotaFiscalService