<template>
  <b-table
    :fields="table.fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  />
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  computed: {
    items() {
      return this.dados.impostos;
    },
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: 'tipoImpostoDescricao',
            label: this.$t('SUPRIMENTOS.TIPO_IMPOSTO'),
            sortable: true,
          },
          {
            key: 'valor',
            label: this.$t('SUPRIMENTOS.VALOR'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
        ],
      },
    };
  },
};
</script>
