import Swal from 'sweetalert2';
import i18n from '../../i18n/i18n';
const styleDefault = {
  confirmButton: 'btn-primary py-2 px-5',
  denyButton: 'btn-primary py-2 px-5',
  cancelButton: 'btn-secondary py-2 px-5',
  popup: 'container-xxs',
  icon: 'smaller-icon',
};

const sucesso = (titulo, texto, textoBotao) => {
  return Swal.fire({
    title: titulo || i18n.t('GERAL.SUCESSO'),
    text: texto || i18n.t('GERAL.ACAO_CONFIRMADA'),
    icon: 'success',
    confirmButtonText: textoBotao || i18n.t('GERAL.OK'),
    customClass: styleDefault,
  });
};

const erro = (titulo, texto, textoBotao) => {
  return Swal.fire({
    title: titulo || i18n.t('GERAL.ERRO'),
    text: texto || i18n.t('GERAL.ACAO_ERRO'),
    icon: 'error',
    confirmButtonText: textoBotao || i18n.t('GERAL.OK'),
    customClass: styleDefault,
  });
};

const warning = (titulo, texto, textoBotao) => {
  return Swal.fire({
    title: titulo || i18n.t('GERAL.AVISO'),
    text: texto || i18n.t('GERAL.ACAO_AVISO'),
    icon: 'warning',
    confirmButtonText: textoBotao || i18n.t('GERAL.OK'),
    customClass: styleDefault,
  });
};

const confirmacao = async (titulo, html) => {
  return await Swal.fire({
    title: titulo || i18n.t('GERAL.CONFIRMAR'),
    html: html || i18n.t('GERAL.MENSAGEM_CONFIRMAR_ACAO'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: i18n.t('GERAL.SIM'),
    cancelButtonText: i18n.t('GERAL.NAO'),
    reverseButtons: true,
    customClass: {
      ...styleDefault,
      actions: 'px-4 w-100 d-flex justify-content-between',
    },
  });
};

const showErrors = (err) => {
  if (err.response.data?.errors?.length) {
    err.response.data.errors.forEach((element) => {
      element.tipoNotificacao === 'Erro' || element.tipoNotificacao === null
        ? erro(i18n.t('GERAL.ERRO'), element.mensagem)
        : warning(i18n.t('GERAL.AVISO'), element.mensagem);
    });
  } else {
    erro(i18n.t('GERAL.OCORREU_UM_ERRO_INTERNO'));
  }
};

export default {
  sucesso,
  erro,
  confirmacao,
  warning,
  showErrors,
};
