<template>
  <div class="text-center">
    <b-button-group class="mt-2">
      <b-button variant="primary" v-if="ehPrimeiraTab" @click="anterior">
        {{ $t('GERAL.PASSO_ANTERIOR') }}
      </b-button>
      <b-button variant="primary" v-if="ehUltimaTab" @click="proximo" class="ml-1">
        {{ $t('GERAL.PROXIMO_PASSO') }}
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  props: {
    tabIndex: { type: Number, default: Number },
    ultimaTab: { type: Number, default: Number },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  watch: {
    tabIndex() {
      this.activeTab = this.tabIndex;
    },
  },
  computed: {
    ehPrimeiraTab() {
      return this.activeTab !== 0;
    },
    ehUltimaTab() {
      return this.activeTab !== this.ultimaTab;
    },
  },
  methods: {
    anterior() {
      this.$emit('alterar-tab', this.activeTab - 1);
    },
    proximo() {
      this.$emit('alterar-tab', this.activeTab + 1);
    },
  },
};
</script>
