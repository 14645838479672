<template>
  <div>
    <cabecalho title="SUPRIMENTOS_LANCAMENTOS_NF.DADOS_PAGAMENTO" />
    <tabela-dados-pagamento :dados="dados" />

    <cabecalho title="SUPRIMENTOS_LANCAMENTOS_NF.DADOS_PARCELAMENTO" />
    <tabela-parcelamento :dados="dados" />
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import { TabelaDadosPagamento, TabelaParcelamento } from './index.js';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
    TabelaDadosPagamento,
    TabelaParcelamento,
  },
};
</script>
