<template>
  <b-table
    :fields="table.fields"
    :items="table.items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  />
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
import formatador from '@/common/utils/formatador';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  watch: {
    dados() {
      if (this.dados) {
        this.table.items.push(this.dados);
      }
    },
  },
  data() {
    return {
      table: {
        items: [],
        fields: [
          {
            key: 'numeroNotaFiscal',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.NUMERO_NOTA_FISCAL'),
            sortable: true,
          },
          {
            key: 'fornecedor',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.CNPJ'),
            formatter: (value) => {
              return value
                ? `${value.razaoSocial} - ${formatador.cnpjComPontuacao(
                    value.cnpj
                  )}`
                : '-';
            },
            sortable: true,
          },
          {
            key: 'dataEmissao',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.DATA_EMISSAO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataVencimento',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.DATA_VENCIMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
        ],
      },
    };
  },
};
</script>
