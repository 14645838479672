<template>
  <b-table
    :fields="table.fields"
    :items="parcelas"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  />
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  props: {
    dados: { type: Object, default: Object },
  },
  computed: {
    parcelas() {
      return this.dados.notasFiscaisParcelas;
    },
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: 'dataLancamento',
            label: this.$t('SUPRIMENTOS.DATA_LANCAMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataVencimento',
            label: this.$t('SUPRIMENTOS.DATA_VENCIMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'valorDocumento',
            label: this.$t('SUPRIMENTOS.VALOR'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
        ],
      },
    };
  },
};
</script>
